import React from 'react';
import './Spinner2.css';

const spinner = () => {

  return (
    <div className="div-spinner">
      <div className="spinner" ></div>
    </div>

  );
}

export default spinner;