import andreFoto from "../assets/images/QuemSomos/Membros/andre.jpg";
import arieleFoto from "../assets/images/QuemSomos/Membros/ariele.png";
import brunoFoto from "../assets/images/QuemSomos/Membros/bruno.jpeg";
import carlaFoto from "../assets/images/QuemSomos/Membros/carla.jpeg";
import daltamirFoto from "../assets/images/QuemSomos/Membros/daltamir.jpg";
import diegoFoto from "../assets/images/QuemSomos/Membros/diego.jpg";
import edsonFoto from "../assets/images/QuemSomos/Membros/edson.jpeg";
import erikaFoto from "../assets/images/QuemSomos/Membros/erika.jpeg";
import heriveltoFoto from "../assets/images/QuemSomos/Membros/herivelto.jpg";
import joaoFoto from "../assets/images/QuemSomos/Membros/joao.jpg";
import joiceFoto from "../assets/images/QuemSomos/Membros/joice.jpeg";
import jorgeFoto from "../assets/images/QuemSomos/Membros/jorge.jpeg";
import kaioFoto from "../assets/images/QuemSomos/Membros/kaio.jpeg";
import luizRFoto from "../assets/images/QuemSomos/Membros/luizR.jpeg";
import manuFoto from "../assets/images/QuemSomos/Membros/manu.jpeg";
import marcioFoto from "../assets/images/QuemSomos/Membros/marcio.jpg";
import marianaFoto from "../assets/images/QuemSomos/Membros/mariana.jpg";
import rodolfoFoto from "../assets/images/QuemSomos/Membros/rodolfo.jpeg";
import samuelFoto from "../assets/images/QuemSomos/Membros/samuel.jpeg";
import scheylaFoto from "../assets/images/QuemSomos/Membros/scheyla.jpeg";
import zainneFoto from "../assets/images/QuemSomos/Membros/zainne.jpeg";

export const membros = [
  {
    name: "André Bordignon",
    photo: andreFoto,
    role: "Professor / Pesquisador",
    research2: "Linha 2",
    researchLink2: "/quemsomos#linha2",
  },
  {
    name: "Ariele Contrigiani",
    photo: arieleFoto,
    role: "Técnica",
  },
  {
    name: "Bruno Povliuk",
    photo: brunoFoto,
    role: "Aluno",
    research2: "Linha 2",
    researchLink2: "/quemsomos#linha2",
    research3: "Linha 3",
    researchLink3: "/quemsomos#linha3",
  },
  {
    name: "Carla Vanessa",
    photo: carlaFoto,
    role: "Nutricionista",
  },
  {
    name: "Daltamir Maia",
    photo: daltamirFoto,
    role: "Professor / Pesquisador",
    research3: "Linha 3",
    researchLink3: "/quemsomos#linha3",
  },
  {
    name: "Diego Figueira",
    photo: diegoFoto,
    role: "Professor / Pesquisador",
    research1: "Linha 1",
    researchLink1: "/quemsomos#linha1",
    research2: "Linha 2",
    researchLink2: "/quemsomos#linha2",
  },
  {
    name: "Edson Duarte",
    photo: edsonFoto,
    role: "Professor / Pesquisador",
    research3: "Linha 3",
    researchLink3: "/quemsomos#linha3",
  },
  {
    name: "Erika Batista",
    photo: erikaFoto,
    role: "Professora / Pesquisadora",
    research1: "Linha 1",
    researchLink1: "/quemsomos#linha1",
    research2: "Linha 2",
    researchLink2: "/quemsomos#linha2",
    research3: "Linha 3",
    researchLink3: "/quemsomos#linha3",
  },
  {
    name: "Herivelto Rocha",
    photo: heriveltoFoto,
    role: "Professor / Pesquisador",
    research1: "Linha 1",
    researchLink1: "/quemsomos#linha1",
    research2: "Linha 2",
    researchLink2: "/quemsomos#linha2",
    research3: "Linha 3",
    researchLink3: "/quemsomos#linha3",
  },
  {
    name: "João Aleixo",
    photo: joaoFoto,
    role: "Técnico",
    research2: "Linha 2",
    researchLink2: "/quemsomos#linha2",
  },
  {
    name: "Joice Mendes",
    photo: joiceFoto,
    role: "Professora",
    research2: "Linha 2",
    researchLink2: "/quemsomos#linha2",
    research3: "Linha 3",
    researchLink3: "/quemsomos#linha3",
  },
  {
    name: "Jorge Henrique",
    photo: jorgeFoto,
    role: "Técnico Educador Social",
    // research2: 'Linha 2',
    // researchLink2: '/quemsomos#linha2',
  },
  {
    name: "Kaio Rodrigues",
    photo: kaioFoto,
    role: "Aluno",
    research2: "Linha 2",
    researchLink2: "/quemsomos#linha2",
    research3: "Linha 3",
    researchLink3: "/quemsomos#linha3",
  },
  {
    name: "Luiz Roberto",
    photo: luizRFoto,
    role: "Aluno",
    research1: "Linha 1",
    researchLink1: "/quemsomos#linha1",
    research2: "Linha 2",
    researchLink2: "/quemsomos#linha2",
  },
  {
    name: "Manuela Gracie",
    photo: manuFoto,
    role: "Aluna",
    research1: "Linha 1",
    researchLink1: "/quemsomos#linha1",
  },
  {
    name: "Mariana Saragiotto",
    photo: marianaFoto,
    role: "Professora / Pesquisadora",
    research3: "Linha 3",
    researchLink3: "/quemsomos#linha3",
  },

  {
    name: "Marcio Miranda",
    photo: marcioFoto,
    role: "Professor / Pesquisador",
    research2: "Linha 2",
    researchLink2: "/quemsomos#linha2",
    research3: "Linha 3",
    researchLink3: "/quemsomos#linha3",
  },

  {
    name: "Rodolfo Medina",
    photo: rodolfoFoto,
    role: "Pesquisador",
    research1: "Linha 1",
    researchLink1: "/quemsomos#linha1",
  },
  {
    name: "Samuel Dias",
    photo: samuelFoto,
    role: "Aluno",
    research3: "Linha 3",
    researchLink3: "/quemsomos#linha3",
  },
  {
    name: "Scheyla Saori",
    photo: scheylaFoto,
    role: "Técnica Agrônoma",
    research3: "Linha 3",
    researchLink3: "/quemsomos#linha3",
  },
  {
    name: "Zainne Soeiro Saori",
    photo: zainneFoto,
    role: "Aluna",
    research2: "Linha 2",
    researchLink2: "/quemsomos#linha3",
    research3: "Linha 3",
    researchLink3: "/quemsomos#linha3",
  },
];
